import React, { useState } from 'react';
import ChatBox from "../assets/logos/note-pad.png";

function BookingForm() {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <>
      {showPopup && (
        <div className="fixed top-0 left-0 right-0 bg-[rgba(0,0,0,0.5)] z-50 flex justify-center items-center h-screen">
          <div className="md:w-[40%] rounded-2xl bg-orange-50 p-6">
            <h2 className="text-2xl md:text-3xl tracking-wide p-2 font-times text-center">Enquire Now</h2>
            <iframe
              title="Contact form"
              className="text-3xl w-full md:text-4xl h-[320px] md:h-[22vw] text-white rounded-2xl font-semibold font-serif"
              id="Contact_Form"
              src="https://account.solidperformers.com/capture_form_data/OTM3"
              style={{ border: 0 }}
            ></iframe>
            <button className="block mx-auto mt-4 bg-[#74613C] bg-opacity-50 border-2 md:hover:bg-white md:hover:text-black text-white border-white duration-700  rounded-3xl px-4 py-2" onClick={togglePopup}>
              Close
            </button>
          </div>
        </div>
      )}

      <div style={{ position: "fixed", bottom: "80px", right: "20px", zIndex: 30 }}>
        <img
          src={ChatBox}
          alt="Contact Form"
          style={{ width: "50px", height: "50px", cursor: "pointer" }}
          onClick={togglePopup}
        />
      </div>
    </>
  );
}

export default BookingForm;
