import React from "react";

const WorkingProcess = () => {
  return (

    <div className="p-5 md:p-20 md:pt-10 flex flex-col gap-5 bg-white" data-scroll data-scroll-section data-scroll-speed="0.1">
      <h2 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
        OUR WORKING PROCESS
      </h2>

      <div className="flex flex-col gap-5 md:flex-row justify-center items-center">
        {/* card-1.............. */}
        <section className=" hover:bg-[#E9CB9A] hover:p-5 duration-500 rounded-e-full">

          <p className="text-xl font-extrabold rounded-full border-black border-2 bg-white w-fit p-2 ">
            1
          </p>

          <h3 className=" py-3 text-base  md:text-xl font-french-canon">
            Understanding the Need
          </h3>

          <p className="hover:text-white">
            Client requirements are taken into consideration and plans are
            initiated.
          </p>

        </section>

        {/* card-2.............. */}
        <section className=" hover:bg-[#E9CB9A] hover:p-5 duration-500  rounded-e-full">

          <p className="text-xl font-extrabold rounded-full border-black border-2 bg-white w-fit p-2 ">
            2
          </p>

          <h3 className=" py-3 text-base  md:text-xl font-french-canon">
            Idea & Design
          </h3>

          <p className="hover:text-white">
            Our professional team of architect and Designers shapes up your
            ideas into designs.
          </p>

        </section>

        {/* card-3.............. */}
        <section className=" hover:bg-[#E9CB9A] hover:p-5 duration-500  rounded-e-full">
          <p className="text-xl font-extrabold rounded-full border-black border-2 bg-white w-fit p-2 ">
            3
          </p>
          <h3 className=" py-3 text-base  md:text-xl font-french-canon">
            Specification
          </h3>
          <p className="hover:text-white">
            Detailing of minute things like materials, quantity, quality of
            workmanship is taken care of.
          </p>
        </section>

        {/* card-4.............. */}
        <section className=" hover:bg-[#E9CB9A] hover:p-5 duration-500  rounded-e-full">
          <p className="text-xl font-extrabold rounded-full border-black border-2 bg-white w-fit p-2 ">
            4
          </p>

          <h3 className=" py-3 text-base  md:text-xl font-french-canon">
            Execution
          </h3>
          <p className="hover:text-white">
            Performing all the strategies to get the project across the finish
            line.
          </p>

        </section>
      </div>
    </div>
  );
};

export default WorkingProcess;
