import React from "react";
import VastuDetails from "../component/servicepage/VastuDetails";
import VastuBanner from "../component/servicepage/VastuBanner";
import Header2 from './Header2'
import Footer from "./Footer";
import { Helmet } from 'react-helmet';

const VastuConsultancy = () => {
  return (
    <div>
       <Helmet>
        <title> Vastu Consultancy In Delhi NCR | Vastu Consultants In Noida</title>
        <meta name="description" content="Transform your home with Vastu consultants in Noida, Delhi, and Gurgaon renowned for their expertise. Get effective Vastu solutions that promote prosperity" />
      </Helmet>
        <Header2/>
      <VastuBanner />
      <VastuDetails />
      <Footer/>
    </div>
  );
};

export default VastuConsultancy;
