import React from 'react';
import { Link } from 'react-router-dom';

import banner from "../../assets/photos/ongoingProjects/p1Banner.jpg";

const Project1Banner = () => {
  return (
    <div
      className="min-h-[70vw] lg:min-h-[35vw] bg-cover bg-center flex justify-center items-center w-screen"
      style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    ><h3 className='text-white text-2xl md:text-6xl z-10 font-french-canon text-center'>Project Details
     {/* <Link to="/">
          <span className=" text-black text-lg md:text-2xl  bg-[#f6c87f] hover:text-[#f6c87f]  md:px-2 p-1 hover:bg-white">HOME</span>
          </Link>  */}
    </h3>
   
      
    </div>
  );
};

export default Project1Banner;
