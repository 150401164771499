import React from 'react'
// import Banner from './Banner'
import AboutUs from './AboutUs'
import ContactDetails from '../component/contact/ContactDetails'
import WorkingProcess from './WorkingProcess'
import Testimonials from './Testimonial'
import LocomotiveScroll from 'locomotive-scroll';
import NoticeBanner from './NoticeBanner'
import banner from '../assets/photos/SMH-banner2.webp'
// import Header from './Header';
import Footer from './Footer'
import Header2 from './Header2'

const Home = () => {
const locomotiveScroll = new LocomotiveScroll();

  return (
    <div className='min-h-screen bg-zinc-100'>
    <Header2/>
    <img src={banner} alt='ShapeMyHouse Banner'/>
    {/* <Banner/> */}
    <AboutUs/>
    <WorkingProcess/>
    <ContactDetails/>
    <NoticeBanner/>
    <Testimonials/>
    <Footer/>
    </div>
  )
}

export default Home
