import React from "react";
// import PDFViewer from 'react-pdf-viewer';
// import img1 from "../../assets/photos/collaboration_page/img1.webp";
import logo from "../../assets/photos/ongoingProjects/customer-support.png";
import pic from "../../assets/photos/collaboration_page/CollabrationPic.webp"
// import SMH_Specification from "../../../../public/assets/pdf/SMH_Specification.pdf";
// import SMH_PPT from "../../../../public/assets/pdf/SMH_PPT.pdf"
// import Gurgaon_PPT from "../../../../public/assets/pdf/Gurgaon_PPT.pdf"

const CollaborationDetails = () => {
  const handleButtonClick = () => {
    window.open("/contact_us", "_blank");
  };
  // const pdfFile1 = {SMH_Specification};
  // const pdfFile2 = {SMH_PPT};
  // const pdfFile3 = {Gurgaon_PPT};

  return (
    <div className="flex flex-col">
      <section className="flex flex-col gap-5 md:gap-10 w-full p-5 md:p-20">
        <h2 className="text-2xl md:text-3xl font-french-canon">
          What are Collaboration Services?
        </h2>
        <p className="text-base md:text-lg text-black  font-french-canon">
          Do you own a plot but don't want to spend your money to build a new
          home? Shape My House has the perfect solution for you! We will
          construct a beautiful, modern home on your plot at our own expense. In
          exchange, we take ownership of one or more floors in the newly
          constructed home. We also provide just construction services if you
          dont want to trade your one floor.
        </p>

        <h3 className="text-2xl md:text-3xl font-french-canon">
          What we offer in collaboration:
        </h3>
        <div className="flex flex-col gap-5">
  <p className="text-base md:text-lg text-black  font-french-canon">
          From start to end we handle everything. From designing your
          architectural plan and construction to obtaining approvals and
          managing neighbors. We ensure timely delivery of your new home, or we
          pay you a penalty. Before starting any collaboration we will do an
          agreement to avoid any future confusions. So the both the parties can
          understand each other part.
        </p>
        <p className="text-base md:text-lg text-black  font-french-canon">
        You will get a Peace of Mind as Your new home comes with a warranty of up to ten years.
        </p>
          </div>
      

        <h3 className="text-2xl md:text-3xl font-french-canon">
        Why Choose Shape My House?
        </h3>
<div className="flex flex-col gap-5">
  <p className="text-base md:text-lg text-black  font-french-canon">
        No Investment Required: Get a new home without spending your money.
        </p>
        <p className="text-base md:text-lg text-black  font-french-canon">
        Hassle-Free Process: We take care of every detail, ensuring a smooth and stress-free experience.
        </p>
</div>

<div className="flex flex-col xl:flex-row justify-between">
<div className="w-full xl:w-1/2">
  <h3 className="text-2xl md:text-3xl  font-french-canon">
          Areas We Serve:
        </h3>

        <ol className="flex flex-col w-fit text-lg font-times md:text-2xl">
          <li className="flex text-[#CCA605]  p-2  gap-5  ">
            {/* <span className="text-black"> &#10146;</span> */}
            Delhi
          </li>
          <li className="flex text-[#CCA605]  p-2  gap-5  ">
            {/* <span className="text-black"> &#10146;</span> */}
            Gurgaon
          </li>
          <li className="flex text-[#CCA605]  p-2  gap-5  ">
            {/* <span className="text-black"> &#10146;</span> */}
            Noida
          </li>
          <li className="flex text-[#CCA605]  p-2  gap-5  ">
            {/* <span className="text-black"> &#10146;</span> */}
            Greater Noida
          </li>
          <li className="flex text-[#CCA605] p-2  gap-5  ">
            {/* <span className="text-black"> &#10146;</span> */}
            NCR
          </li>
        </ol>

        </div>

<img className="w-full xl:w-1/3 " src={pic} alt="collaboration" />

</div>

        
    
<p className="text-base md:text-lg text-black  font-french-canon">
          Transform your plot into a dream home with Shape My House. Enjoy the
          benefits of modern living without the hassle and expense of
          construction. Contact us today to learn more!
        </p>
      

       
      </section>

      <section className="flex flex-col gap-10 md:gap-14 w-full p-5 md:p-20 md:pt-0 ">
        {/* <div className="flex gap-5 mx-auto">
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_Specification}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile1)}
          >
            SMH Specificatio
          </PDFViewer>
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile2)}
          >
            SMH PPT
          </PDFViewer>
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={Gurgaon_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile3)}
          >
            Gurgaon PPT
          </PDFViewer>
        </div> */}
        <div className="flex flex-col md:flex-row gap-5 md:gap-10">
          
        

         <div className="bg-black w-full xl:w-1/2  flex flex-col justify-center items-center text-white font-glacial md:p-5">
          <img className="w-24 md:w-32 p-1 mx-auto m-3" src={logo} alt="logo" />
          <p className="text-base md:text-xl text-center p-3 font-french-canon">
            Consult with Our Expert & Start today
          </p>
          <div className="py-3">
            <a
              class="text-white text-base font-semibold hover:text-yellow-200"
              href="tel:8510850101"
            >
              <p className="text-lg md:text-2xl text-center font-sans tracking-widest">
                {" "}
                8510 850 101
              </p>
            </a>
            <p className="text-lg text-center font-sans tracking-widest">
              Email:{" "}
              <a
                className="text-white text-base md:hover:text-blue-400 "
                href="mailto:info@tsgoc.in"
              >
                info@tsgoc.in
              </a>
            </p>
          </div>
          <button
            onClick={handleButtonClick}
            className="w-fit bg-white text-[#eeb75e] border-2 border-[#eeb75e] hover:text-white hover:bg-[#F19F1A]  font-french-canon  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none"
          >
            Press
          </button>
        </div>
       
        <div className="flex flex-col w-full xl:w-1/2 gap-3 md:gap-5">
          <a
            href="/service/construction"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Construction
          </a>
          <a
            href="/service/renovation"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Renovation
          </a>
          <a
            href="/service/interior-design"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Interior Design
          </a>
          <a
            href="/service/vastu-consultancy"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Vastu Consultancy
          </a>
          <a
            href="/service/architectural-design"
            target="_blank"
            className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
          >
            Architectural Design
          </a>
        </div>
        </div>
  
       

      

      </section>
    </div>
  );
};

export default CollaborationDetails;
