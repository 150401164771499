import React from "react";
import img from "../../assets/photos/blogs/SMH002.jpg";
import { GoDotFill } from "react-icons/go";

import { Helmet } from "react-helmet";

const Blog2 = () => {
  return (
    <div className="flex flex-col md:flex-row bg-zinc-100">
      <Helmet>
        <title>India Construction Future, Industry Growth And Builders</title>
        <meta
          name="description"
          content="In this blog we have raised some interesting facts like building construction company, construction builders, construction services, civil construction company"
        />
      </Helmet>
      <section className="w-full md:w-2/3 p-3 md:p-10">
        <div className="flex flex-col gap-5 py-5 md:py-10">
          <span className="text-xl font-sans  font-thin">
          2022-11-19 04:00:49

         </span>
          <h1 className="text-xl md:text-4xl font-french-canon text-[#F19F1A] bg-cover">
          The Development and future of the Construction Industry in India
          </h1>
        </div>

        <img className="w-full" src={img} alt="Construction Industry in India" />
        <div className="w-full my-10 flex flex-col gap-3">
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          In India, the construction sector comes in second place after the agricultural sector, which contributes 11% of the country's GDP. Significantly boosting India's economy and generating employment. The construction industry depends on around 250 ancillary economic sectors, including cement, steel, brick, timber, and building supplies. In the construction industry, a unit increase in spending has the potential to multiply income up to five times. 
          </p>
          
        <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        The development of the real estate includes residential, commercial, and civil construction company, infrastructure, which encompasses the construction of roads, railways, and power plants, and industrial uplift, which includes the construction of oil and gas refineries, pipelines, textile factories, and other facilities are the major segments of the construction builders industry growth. 
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          In order to increase the growth of industry traditional financing techniques have undergone a significant transformation to make way for structured finance, private equity, and initial public offerings. As a result, it is projected that the private sector's share in overall infrastructure investment will rise to 50%. The public sector will bear the remaining burden. 
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          According to Global Data’s “Construction In India” report, it gives detailed market analysis, information, and insights into the Indian construction services industry, including :
          </p>

          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/> The Indian construction industry’s growth
          </p>
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/> Critical insight into the impact of industry trends and issues
          </p>
          <p className="flex text-md md:text-xl px-3 font-glacial text-justify ">
            <GoDotFill className="m-1 ml-0"/> Analysis of the mega-project pipeline
          </p>
          
          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          The report’s key highlights are - 
          </p>
        <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">1.</span> Global Data expects the residential or building construction company to retain its leading position and account for 30.1% of the industry’s total value in 2023. Market expansion over the forecast period is expected to be supported by public and private sector investments in the construction of new residential buildings.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          <span className="font-semibold font-sans">2.</span> In the infrastructure construction market under the Pradhan Mantri Gram Sadak Yojana (PMGSY), the government aims to build roads in the rural and backward areas of the country as a part of the PPP model.
          </p>
          

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">3.</span> Global Data expects the energy and utilities construction market to record a forecast-period CAGR of 10.83% in nominal terms, with a focus to increase the share of renewable energy in terms of total energy consumption and encouraging investment in renewable energy infrastructure.
          </p>
         

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">4.</span> Growth in the institutional construction market will be supported by public and private sector investment in education and healthcare.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
        <span className="font-semibold font-sans">5.</span> The total construction project in India – as tracked by Global Data, including all mega projects with a value above US$25 million – stands at INR82.5 trillion (US$1.2 trillion).
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The main factors boosting the need for residential buildings include the demand-supply gap for housing, favorable demographics, rising levels of affordability, and the availability of financing options. There would be a scarcity of 26.53 million homes, according to projections of the housing deficit, which presents a significant investment opportunity. Additionally, it is expected that demand for office space would continue as India becomes a more popular outsourcing location. Also, it is expected that the trend in organised retail would lead to a great demand for real estate construction.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Even while long-term variables are probably in the developers' favour, the immediate future appears bright again. The improvement in sales and cost-effectiveness is putting a spotlight on real estate industry leaders' profitability. Access to affordable housing is expected to pressurise margins. 
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          India is on the move towards a phase of sustained growth in the infrastructure build-up. The construction industry regained growth movement in 2018 as well as 2019, with output hovering around 8% which was at 1.9% in 2017. The industry is expected to continue to expand over the period (2022-2023), driven by the government’s efforts and large spending on housing, road, ports, water supply, and airport development. The government had increased its expenditure towards infrastructure development by 20% to INR 6.0 trillion (US$89.2 billion) in FY 2018-2019 with continued investment in transport infrastructure, energy, and residential projects under flagship programs such as Bharatmala scheme, Housing for All 2022, the UDAN (Ude Desh ka Aam Nagrik) scheme and the Ayushman Bharat program. Population growth and urbanisation will also drive the need for better infrastructure facilities and road infrastructure developments in the country.
          </p>
         
        </div>
      </section>

      <section className="w-full flex flex-col gap-3 md:gap-5 md:w-1/3 py-10 md:py-20 font-french-canon">
        <a href="/blog/know-why-home-orientation-is-necessary" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Know Why Home Orientation Is Necessary
        </a>

        <a href="/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
        </a>

        <a href="/blog/where-to-purchase-residential-plots-in-gurgaon?" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Where to purchase residential plots in Gurgaon?
        </a>

        <a href="/blog/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Construction ban reimposed in Delhi NCR as air quality worsens
        </a>

        <a href="/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Factors that make house in Gurugram the hot property at the moment -
        </a>

        <a href="/blog/simple-options-for-home-renovation-in-india--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Simple Options for Home Renovation in India -
        </a>

        <a href="/blog/upcoming-smart-home-trends-&-innovations-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Upcoming Smart Home Trends & Innovations in <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/performance-of-indian-real-estate-through-fy23-and-future-prospects" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Performance Of Indian Real Estate Through FY<span className="font-sans font-semibold">23</span> And Future Prospects
        </a>

        <a href="/blog/key-construction-industry-trends-to-look-out-for-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Key Construction Industry Trends To Look Out For In <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/Modern-Homes-vs-Ancient-Homes:-Top-details-you-were-Overlooked" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Modern Homes vs Ancient Homes: Top <span className="font-sans font-semibold">6</span> details you've Overlooked
        </a>

        <a href="/blog/top-5-collaboration-tools-for-real-estate" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Top <span className="font-sans font-semibold">5</span> Collaboration Tools for Real Estate
        </a>

        <a href="/blog/introduction-of-the-best-builders-of-delhi---shape-my-house" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Introduction of the Best Builder of Delhi - Shape My House
        </a>

        <a href="/blog/how-do-you-figure-out-the-property-valuation" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        How do you figure out the Property Valuation
        </a>
      </section>
    </div>
  );
};

export default Blog2;
