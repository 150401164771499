import React from "react";
import AboutCarousal from "./AboutCarousal";
// import { motion } from "framer-motion";

import img1 from "../assets/photos/service_page/collaboration_logo.jpg";
import img2 from "../assets/photos/service_page/construction_logo.jpg";

import img3 from "../assets/photos/renovation_page/RenovationPic.webp";
import img4 from "../assets/photos/InteriorDesigning_Page/InteriorPic.webp";
import img5 from "../assets/photos/Architectural_Page/ArchitecturalBanner.webp";
import img6 from "../assets/photos/vastu_page/VastuPic.webp";

const AboutUs = () => {
  return (
    <div
      className="min-h-screen"
      data-scroll
      data-scroll-section
      data-scroll-speed="-0"
    >
      <AboutCarousal />
      <div className="p-5 md:p-20 md:pt-5 flex flex-col gap-5 md:gap-10">
        <section
          className="flex flex-col md:flex-row justify-center items-center gap-10"
          data-scroll
          data-scroll-section
          data-scroll-speed="0.1"
        >
          <div className="flex flex-col gap-5">
            <h2 className="text-[#CCA605] text-sm md:text-lg font-french-canon">
              PROPERTY COLLABORATION SERVICES
            </h2>
            <h3 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
              Collaborate with Shape My House
            </h3>
            <p className="text-sm md:text-lg  font-glacial text-pretty text-justify">
              Shape My House is always on the lookout for like-minded
              individuals and organizations to collaborate with. Whether you're a contractor, an architect, a designer, a colonizer in Noida or any other professional in the home improvement industry, we believe that pooling our
              expertise and resources can lead to innovative solutions and
              better experiences for our customers. Shape My House offers the best property collaboration services with proven track record as one of the best real estate developer in Noida.
            </p>
            <a
              className=" text-sm md:text-lg rounded-xl p-2 bg-black text-[#f6c87f]  w-fit hover:text-white hover:bg-[#CCA605]  font-glacial "
              href="/service/collaboration"
            >
              <button>Read More</button>
            </a>
          </div>
          <img
            className="w-full md:size-56 rounded-xl md:hover:scale-125 duration-500"
            src={img1}
            alt=" PROPERTY COLLABORATION SERVICES"
          />
          {/* <div
            style={{
              transformStyle: "preserve-3d",
              transform: "rotateY(-30deg) rotateX(15deg)",
            }}
            className=" bg-gradient-to-r from-black to-zinc-400"
          >
            <motion.div
              initial={{
                transform: "translateZ(8px) translateY(-2px)",
              }}
              animate={{
                transform: "translateZ(32px) translateY(-8px)",
              }}
              transition={{
                repeat: Infinity,
                repeatType: "mirror",
                duration: 2,
                ease: "easeInOut",
              }}
              className="w-full md:size-56">
                <img
             className="w-full md:size-56"
              src={logo1}
              alt="logo1"
            />
            </motion.div>
          
          </div> */}
        </section>

        <section
          className="flex flex-col  md:flex-row justify-center items-center gap-10"
          data-scroll
          data-scroll-section
          data-scroll-speed="0"
        >
          <div className="flex flex-col gap-5">
            <h2 className="text-[#CCA605] text-sm md:text-lg font-french-canon">
              CONSTRUCTION COMPANY IN DELHI NCR
            </h2>
            <h3 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
              Construction with Shape My House
            </h3>
            <p className="text-sm md:text-lg  font-glacial text-pretty text-justify">
              For all of your modern construction needs in Delhi NCR, there is a
              suitable answer. With the use of current design and technology,
              Shape My House offers services like- collaboration and
              construction. We make an effort to choose the best course of
              action for our clients. We are more than just builders in Noida; we are architects who bring dreams to life. Specializing in residential projects, we
              redefine the standards of excellence. As a premier builder in
              Delhi, our commitment to quality and precision is unwavering. Your
              vision is our mission – we bring it to life with finesse.
            </p>
            <a
              className=" text-sm md:text-lg rounded-xl p-2 bg-black text-[#f6c87f]  w-fit hover:text-white hover:bg-[#CCA605]  font-glacial "
              href="/service/construction"
            >
              <button>Read More</button>
            </a>
          </div>

          <img
            className="w-full md:size-56 rounded-xl md:hover:scale-125 duration-500"
            src={img2}
            alt=" CONSTRUCTION COMPANY IN DELHI NCR"
          />
        </section>

        <section
          className="flex flex-col md:flex-row justify-center items-center gap-10"
          data-scroll
          data-scroll-section
          data-scroll-speed="0.1"
        >
          <div className="flex flex-col gap-5">
            <h2 className="text-[#CCA605] text-sm md:text-lg font-french-canon">
              RENOVATION SERVICE IN DELHI NCR
            </h2>
            <h3 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
              Renovate with Shape My House
            </h3>
            <p className="text-sm md:text-lg  font-glacial text-pretty text-justify">
              Founded by Agam Aggarwal, Innovate Home Renovation has been
              remodeling homes in India for over a decade. When planning a home
              improvement project, it's crucial to choose a contractor who is
              dependable, trustworthy, and qualified. We provide comprehensive
              remodeling services for residential, bathroom, interior, and
              exterior projects, specializing in Noida and Delhi.
            </p>
            <a
              className=" text-sm md:text-lg rounded-xl p-2 bg-black text-[#f6c87f]  w-fit hover:text-white hover:bg-[#CCA605]  font-glacial "
              href="/service/renovation"
            >
              <button>Read More</button>
            </a>
          </div>

          <img
            className="w-full md:size-56 rounded-xl md:hover:scale-125 duration-500"
            // src="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719385967/ShapeMyHouse/Pics/RenovationPic_ox71r8.webp"
            src={img3}
            alt=" RENOVATION SERVICE IN DELHI NCR"
          />
        </section>

        <section
          className="flex flex-col md:flex-row justify-center items-center gap-10"
          data-scroll
          data-scroll-section
          data-scroll-speed="0"
        >
          <div className="flex flex-col gap-5">
            <h2 className="text-[#CCA605] text-sm md:text-lg font-french-canon">
              INTERIOR DESIGNING SERVICES
            </h2>
            <h3 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
              Design your Interior with Shape My House
            </h3>
            <p className="text-sm md:text-lg  font-glacial text-pretty text-justify">
              Founded by Agam Aggarwal, Innovate Home Renovation has been
              remodeling homes in India for over a decade. When planning a home
              improvement project, it's crucial to choose a contractor who is
              dependable, trustworthy, and qualified. We provide comprehensive
              remodeling services for residential, bathroom, interior, and
              exterior projects, specializing in Noida and Delhi.
            </p>
            <a
              className=" text-sm md:text-lg rounded-xl p-2 bg-black text-[#f6c87f]  w-fit hover:text-white hover:bg-[#CCA605]  font-glacial "
              href="/service/interior-design"
            >
              <button>Read More</button>
            </a>
          </div>

          <img
            className="w-full md:size-56 rounded-xl md:hover:scale-125 duration-500"
            // src="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719385551/ShapeMyHouse/Pics/InteriorPic_nnumly.webp"
            src={img4}
            alt=" INTERRIOR DESIGNING SERVICES"
          />
        </section>

        <section
          className="flex flex-col md:flex-row justify-center items-center gap-10"
          data-scroll
          data-scroll-section
          data-scroll-speed="0.1"
        >
          <div className="flex flex-col gap-5">
            <h2 className="text-[#CCA605] text-sm md:text-lg font-french-canon">
              ARCHITECTURAL SERVICES
            </h2>
            <h3 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
              Expert Architectural Design with Shape My House
            </h3>
            <p className="text-sm md:text-lg  font-glacial text-pretty text-justify">
              Our team of talented architects brings years of expertise and a
              passion for creative design to every project. We prioritize your
              needs and vision, ensuring that the final design exceeds your
              expectations. Upholding the highest standards of quality in all
              aspects of our work, we provide comprehensive architectural design
              services from the initial consultation to the project's
              conclusion.
            </p>
            <a
              className=" text-sm md:text-lg rounded-xl p-2 bg-black text-[#f6c87f]  w-fit hover:text-white hover:bg-[#CCA605]  font-glacial "
              href="/service/architectural-design"
            >
              <button>Read More</button>
            </a>
          </div>

          <img
            className="w-full md:size-56 rounded-xl md:hover:scale-125 duration-500"
            // src="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719381604/ShapeMyHouse/Pics/Architecturalpic_f9nfgy.jpg"
            src={img5}
            alt="ARCHITECTURAL SERVICES"
          />
        </section>

        <section
          className="flex flex-col md:flex-row justify-center items-center gap-10"
          data-scroll
          data-scroll-section
          data-scroll-speed="0"
        >
          <div className="flex flex-col gap-5">
            <h2 className="text-[#CCA605] text-sm md:text-lg font-french-canon">
              VASTU CONSULTANCY SERVICES
            </h2>
            <h3 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
              Vastu Consultancy with Shape My House
            </h3>
            <p className="text-sm md:text-lg  font-glacial text-pretty text-justify">
              Ready to transform your space with the ancient wisdom of Vastu
              Shastra? Contact Shape My House today for a personalized Vastu
              consultation. Let our experts help you create a home or office
              that radiates positive energy and supports your well-being and
              success.
            </p>
            <a
              className=" text-sm md:text-lg rounded-xl p-2 bg-black text-[#f6c87f]  w-fit hover:text-white hover:bg-[#CCA605]  font-glacial "
              href="/service/vastu-consultancy"
            >
              <button>Read More</button>
            </a>
          </div>

          <img
            className="w-full md:size-56 rounded-xl md:hover:scale-125 duration-500"
            // src="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719384011/ShapeMyHouse/Pics/VastuPic_iadir3.webp"
            src={img6}
            alt="VASTU CONSULTANCY SERVICES"
          />
        </section>
      </div>
    </div>
  );
};

export default AboutUs;
