const BannerAdvert = () => {
  return (
    <div className="md:relative">
      <img
        src="https://res.cloudinary.com/dodlkc2b8/image/upload/v1719982403/SMH-Banner1_pr4kn3.png"
        alt="ShapeMyHouse Banner"
      />

      <div className="bg-[#f3edea] md:w-[30%] rounded-2xl md:absolute md:top-[55%] md:right-20 md:transform md:-translate-y-1/2 py-5">
        <h2 className="text-xl md:text-2xl tracking-wide p-2 font-times text-center">
          Book FREE Appointment
        </h2>
        <p className="text-base md:text-lg p-2 font-times text-center">
          For Best Quality Construction At Affordable Rates
        </p>

        <iframe
          title="Contact form"
          className="text-3xl w-full md:text-4xl h-[320px] md:h-[21vw] text-white rounded-2xl font-semibold font-serif"
          id="Contact_Form"
          src="https://account.solidperformers.com/capture_form_data/OTM3"
          border="0"
          style={{ border: 0 }}
        ></iframe>

        <p className="text-sm font-times text-center">
          * By submitting this form, I confirm that I have read and agreed to
          accept Shape My House <a href="/privacy_policy" className="text-red-400">privacy policy </a> 
        </p>
      </div>
    </div>
  );
};

export default BannerAdvert;
