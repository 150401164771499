import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './screens/Home';
// import Header from './screens/Header';
// import Footer from './screens/Footer';
import AboutUs from './screens/AboutUs';
import ContactUs from './screens/Contact';
import PrivacyPolicy from './screens/PrivacyPolicy';
import Service from './screens/Service';
import Vendor from './screens/Vendor';
import Collaboration from './screens/Collaboration';
import Construction from './screens/Construction';
import Blogs from './component/blogs/Blogs';
import Blog14 from './component/blogs/Blog14';
import Blog13 from './component/blogs/Blog13';
import Blog12 from './component/blogs/Blog12';
import Blog11 from './component/blogs/Blog11';
import Blog10 from './component/blogs/Blog10';
import Blog9 from './component/blogs/Blog9';
import Blog8 from './component/blogs/Blog8';
import Blog7 from './component/blogs/Blog7';
import Blog6 from './component/blogs/Blog6';
import Blog5 from './component/blogs/Blog5';
import Blog4 from './component/blogs/Blog4';
import Blog3 from './component/blogs/Blog3';
import Blog2 from './component/blogs/Blog2';
import Blog1 from './component/blogs/Blog1';
import OurProject from './screens/OurProject';
import Project1 from './component/ongoingProject/Project1';
import Project2 from './component/ongoingProject/Project2';
import Renovation from './screens/Renovation';
import InteriorDesign from './screens/InteriorDesign';
import VastuConsultancy from './screens/VastuConsultancy';
import ArchitecturalDesign from './screens/ArchitecturalDesign';
import AdvertHome from './component/advert/AdvertHome';

function App() {
  return (
    <BrowserRouter>
    {/* <Header/> */}
 <Routes>
    <Route exact path='/' element={<Home/>}/> 
    <Route exact path='/about_us' element={<AboutUs/>}/>
    <Route exact path='/contact_us' element={<ContactUs/>}/>
    <Route exact path='/privacy_policy' element={<PrivacyPolicy/>}/>
    <Route exact path='/service' element={<Service/>}/>
    <Route exact path='/our-projects' element={<OurProject/>}/>
    <Route exact path='/ongpro2' element={<Project1/>}/>
    <Route exact path='/4-bhk-apartment-in-dlf-phase-3-gurgaon' element={<Project2/>}/>

    <Route exact path='/advert' element={<AdvertHome/>}/>


    <Route exact path='/vendor_registration' element={<Vendor/>}/>
    <Route exact path='/service/collaboration' element={<Collaboration/>}/>
    <Route exact path='/service/construction' element={<Construction/>}/>
    <Route exact path='/service/renovation' element={<Renovation/>}/>
    <Route exact path='/service/interior-design' element={<InteriorDesign/>}/>
    <Route exact path='/service/vastu-consultancy' element={<VastuConsultancy/>}/>
    <Route exact path='/service/architectural-design' element={<ArchitecturalDesign/>}/>


    
    <Route exact path='/blog' element={<Blogs/>}/>
    <Route exact path='/blog/how-do-you-figure-out-the-property-valuation' element={<Blog14/>}/>
    <Route exact path='/blog/introduction-of-the-best-builders-of-delhi---shape-my-house' element={<Blog13/>}/>
    <Route exact path='/blog/top-5-collaboration-tools-for-real-estate' element={<Blog12/>}/>
    <Route exact path='/blog/Modern-Homes-vs-Ancient-Homes:-Top-details-you-were-Overlooked' element={<Blog11/>}/>
    <Route exact path='/blog/key-construction-industry-trends-to-look-out-for-in-2023' element={<Blog10/>}/>    
    <Route exact path='/blog/performance-of-indian-real-estate-through-fy23-and-future-prospects' element={<Blog9/>}/>    
    <Route exact path='/blog/upcoming-smart-home-trends-&-innovations-in-2023' element={<Blog8/>}/>    
    <Route exact path='/blog/simple-options-for-home-renovation-in-india--' element={<Blog7/>}/>   
    <Route exact path='/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--' element={<Blog6/>}/>    
    <Route exact path='/blog/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens' element={<Blog5/>}/>    
    <Route exact path='/blog/where-to-purchase-residential-plots-in-gurgaon?' element={<Blog4/>}/>    
    <Route exact path='/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon' element={<Blog3/>}/>    
    <Route exact path='/blog/the-development-and-future-of-the-construction-industry-in-india' element={<Blog2/>}/>    
    <Route exact path='/blog/know-why-home-orientation-is-necessary' element={<Blog1/>}/>    
    
 </Routes>
 {/* <Footer/> */}
</BrowserRouter>
  );
}

export default App;
