import React from "react";
import BookingForm from "./BookingForm";

const CompanyPolicies = () => {
  return (
    <div>
      <h3 className="text-[#CCA605] text-lg md:text-2xl xl:text-4xl text-center font-french-canon py-5">
        Why Shape My House is Different
      </h3>

      <h1 className="w-fit text-[#74613C] mx-auto text-sm md:text-2xl font-serif text-center px-3">
        Home Construction Contractor Near you to build your Dream home with
        Warranty on Structure
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 md:gap-10 m-3 md:m-10">
        <div className="w-full h-28 md:h-56 text-base md:text-lg font-french-canon bg-black bg-opacity-40 text-white hover:bg-orange-100 hover:text-black px-5 text-center rounded-xl p-2 flex flex-col md:gap-5">
          <h2 className="  pt-3">Transparency</h2>
          <p className=" text-sm md:text-base pt-3">
            Transparent specifications and pricing for your peace of mind
          </p>
        </div>

        <div className="w-full h-28 md:h-56 text-base md:text-lg font-french-canon bg-black bg-opacity-40 text-white hover:bg-orange-100 hover:text-black px-5 text-center rounded-xl p-2 flex flex-col md:gap-5">
          <h2 className=" pt-3">Quality Assurance</h2>
          <p className=" text-sm md:text-base pt-3">
            Team of engineers build your Home vis-a-vis local contractors
          </p>
        </div>

        <div className="w-full h-28 md:h-56 text-base md:text-lg font-french-canon bg-black bg-opacity-40 text-white hover:bg-orange-100 hover:text-black px-5 text-center rounded-xl p-2 flex flex-col md:gap-5">
          <h2 className="  pt-3">On-Time Delivery</h2>
          <p className=" text-sm md:text-base pt-3">
            Transparent specifications and pricing for your peace of mind
          </p>
        </div>

        <div className="w-full h-28 md:h-56 text-base md:text-lg font-french-canon bg-black bg-opacity-40 text-white hover:bg-orange-100 hover:text-black px-5 text-center rounded-xl p-2 flex flex-col md:gap-5">
          <h2 className="  pt-3">No Cost Surprises</h2>
          <p className=" text-sm md:text-base pt-3">
            Warranty team for your teething issues post delivery
          </p>
        </div>
      </div>
      <BookingForm />
    </div>
  );
};

export default CompanyPolicies;
