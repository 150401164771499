import React from "react";
import img from "../../assets/photos/blogs/SMH0011.jpg";
import { Helmet } from "react-helmet";

const Blog11 = () => {
  return (
    <div className="flex flex-col md:flex-row bg-zinc-100">
      <Helmet>
        <title>The Primary Dissimilarities between Modern and Ancient Homes</title>
        <meta
          name="description"
          content="Here we have explained the variance between Modern and Ancient Homes. We are the best construction company in Gurgaon and one of the top builders in Gurgaon."
        />
      </Helmet>
      <section className="w-full md:w-2/3 p-3 md:p-10">
        <div className="flex flex-col gap-5 py-5 md:py-10">
          <span className="text-xl font-sans  font-thin">
          2023-01-21 07:03:27
          </span>
          <h1 className="text-xl md:text-4xl font-french-canon text-[#F19F1A] bg-cover">
          Modern Homes vs Ancient Homes: Top <span className="font-sans font-semibold">6</span> details you've Overlooked
          </h1>
        </div>

        <img className="w-full" src={img} alt="Modern Homes design" />
        <div className="w-full my-10 flex flex-col gap-3">
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Modernization is the greatest change in today’s world and it has grasped the world in its arms like an octopus. Everything is renovating or modifying according to the latest trends such as technology, fashion, utilities – and homes are no exception. Traditional or regular homes which embarked some sense and warmth of history, Colonial, Vintage and Victorian styles are slowly heading back, making way for modern architecture.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Nowadays designing of any home having their own chronology as there are a sum total of detailed planning, design, and execution and resonate individual personalities of their inhabitants sensibly.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          So, let’s discuss the major differences between modern houses and ancient homes and how they are incorporated with the techniques like Vastu Shastra? 
          </p>

         

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Here we are trying to find answers to these questions today!
          </p>

          <p className="text-md md:text-xl px-3 font-french-canon  text-justify ">
         <span className="font-sans font-semibold">1)</span> Simplicity over complexity
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Let’s talk about the ancient architecture of the era 19th and 20th centuries where there is a base which is set on some elegant and voluminous designs on the other hand modern houses have it’s core on maximizing space in an economical & at affordable price which can be done by some of the best construction company in Gurgaon. This idea is reflected in every last aspect of how it is carried out.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Ancient architecture focused on the features with embellishments and over-elaboration while. Modern architecture, on the other hand, form a structure showing off its sharp features. For this there is a saying of Da Vinci, "Simplicity is the ultimate sophistication!"
          </p>

          <p className="text-md md:text-xl px-3 font-french-canon text-justify ">
          <span className="font-sans font-semibold">2)</span> Changes in execution
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • In the era of an ancient architecture, local artisans and top notched artisans beautifully carved drawing or pictures on the walls with their hands. While the architecture of modern dwellings depends on talent and creativity
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • With the rapid advancement in building technology, many builders in Gurgaon done modern architecture on the basis of proper strategy and principles like effective planning, scheduling, and management.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • As a result, it helps with project optimization, testing the quality of the materials, and design, paving the way for a well-built building form.
          </p>

          <p className="text-md md:text-xl px-3 font-french-canon text-justify ">
          <span className="font-sans font-semibold">3)</span> Differences in elements
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • From vintage to present time, various notable differences can be seen while comparing modern vs. ancient homes. The importance of storage in modern homes cannot be overstated.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Modern homes provide greater emphasis on minimalistic designs with maximum profitability than traditional homes, which place more emphasis on ornamental elements and ornate interiors as well as the artwork.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Nowadays we can see compact kitchen counters, which provides more area for storage. Although floors which are placed mostly in lighter shades so that it makes room for more light that enhances the space, all the while making it appear bigger!
          </p>

          <p className="text-md md:text-xl px-3 font-french-canon text-justify ">
          <span className="font-sans font-semibold">4)</span> Form and figure
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Because of urbanization and globalization lack of land is an important issue that the modern era faces. There was space back then for buildings to sprawl out. In contrast to horizontal construction, vertical construction is currently gaining greater momentum. And also some of the construction techniques such as MIVAN, RCC, and even 3D Printing is greatly influencing the forms.</p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • In today’s world top builders in Gurgaon are doing more experiments and is willing to deviate from traditional, customary norms while it comes to design.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Investment is a core aspect that rules any field, and similar is to architecture. Exponential growth and rapid advancements in modern times have brought more options. Back then, larger investments were made only for monumental buildings. Now scenes are changing, and today, more efforts and money are put on even commercial buildings globally.
          </p>

          <p className="text-md md:text-xl px-3 font-french-canon text-justify ">
          <span className="font-sans font-semibold">5)</span> Alternatives in materials
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • While ancient architecture had only few options to choose from materials while modern architecture has a goldmine of options! For an example, Terra Cota Bricks which were used at ancient time now have been easily replaced by concrete blocks and plastering by the usage of gypsum boards.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Not just the materials but the technique of embracing has also undergone a huge transformation. For instance, highly efficient hydraulic compacted rammed earth has an alternative to conventional rammed earth usage in swift.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Also, in this period, the industry has become very specific and choosy, and professionals and even labor know what they exactly require rather than making do with whatever limited options come laborers in the way.
          </p>

          <p className="text-md md:text-xl px-3 font-french-canon text-justify ">
          <span className="font-sans font-semibold">6)</span> The system of vastu shastra
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • The spatial geometry, arrangement, orientation, layout, measurement, and scaling – the whole scope of architectural execution is covered in the traditional and believed ancient system of Vastu Shastra.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • The guidelines which were issued for this system were originally followed in the execution of projects in the former age. Now the language, meaning and base of Vastu Shastra have been completely changed and surprisingly it turned out to be a course of study.
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify ">
          • Nowadays Vastu shastra has been counted as a professional course in colleges and institutions and many people make it their profession also it is religiously put to use in architecture.
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          We hope you found the article useful!
          </p>
          
       
        </div>
      </section>

      <section className="w-full flex flex-col gap-3 md:gap-5 md:w-1/3 py-10 md:py-20 font-french-canon">
        <a href="/blog/know-why-home-orientation-is-necessary" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Know Why Home Orientation Is Necessary
        </a>

        <a href="/blog/the-development-and-future-of-the-construction-industry-in-india" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        The Development and future of the Construction Industry in India
        </a>

        <a href="/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
        </a>

        <a href="/blog/where-to-purchase-residential-plots-in-gurgaon?" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Where to purchase residential plots in Gurgaon?
        </a>

        <a href="/blog/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Construction ban reimposed in Delhi NCR as air quality worsens
        </a>

        <a href="/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Factors that make house in Gurugram the hot property at the moment -
        </a>

        <a href="/blog/simple-options-for-home-renovation-in-india--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Simple Options for Home Renovation in India -
        </a>

        <a href="/blog/upcoming-smart-home-trends-&-innovations-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Upcoming Smart Home Trends & Innovations in <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/performance-of-indian-real-estate-through-fy23-and-future-prospects" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Performance Of Indian Real Estate Through FY<span className="font-sans font-semibold">23</span> And Future Prospects
        </a>

        <a href="/blog/key-construction-industry-trends-to-look-out-for-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Key Construction Industry Trends To Look Out For In <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/top-5-collaboration-tools-for-real-estate" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Top <span className="font-sans font-semibold">5</span> Collaboration Tools for Real Estate
        </a>

        <a href="/blog/introduction-of-the-best-builders-of-delhi---shape-my-house" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Introduction of the Best Builder of Delhi - Shape My House
        </a>

        <a href="/blog/how-do-you-figure-out-the-property-valuation" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        How do you figure out the Property Valuation
        </a>
      </section>
    </div>
  );
};

export default Blog11;
