import React from "react";
import { GoDotFill } from "react-icons/go";
// import PDFViewer from 'react-pdf-viewer';
import img1 from "../../assets/photos/InteriorDesigning_Page/InteriorPic.webp";
// import img2 from "../../assets/photos/collaboration_page/img2.webp";
import logo from "../../assets/photos/ongoingProjects/customer-support.png";

// import SMH_Specification from "../../../../public/assets/pdf/SMH_Specification.pdf";
// import SMH_PPT from "../../../../public/assets/pdf/SMH_PPT.pdf"
// import Gurgaon_PPT from "../../../../public/assets/pdf/Gurgaon_PPT.pdf"

const InteriorDesignDetails = () => {
  const handleButtonClick = () => {
    window.open("/contact_us", "_blank");
  };
  // const pdfFile1 = {SMH_Specification};
  // const pdfFile2 = {SMH_PPT};
  // const pdfFile3 = {Gurgaon_PPT};

  return (
    <div className="flex flex-col md:pb-20">
      <section className="flex flex-col gap-10 w-full p-5 md:p-20 md:pb-10">
        <div className="flex flex-col gap-5">
          <h2 className="text-2xl md:text-3xl font-french-canon">
            Shape My House: Interior Design Services in Delhi-NCR
          </h2>
          <p className="text-base md:text-lg text-black  font-french-canon">
            At Shape My House, we understand that your home is your sanctuary,
            and everything about it should be perfect. That's why we are
            dedicated to transforming your home's interior to enhance its beauty
            and functionality. With years of authentic experience, we are among
            the best interior designers in Delhi NCR, committed to bringing your
            vision to life.
          </p>
        </div>

        <div className="flex flex-col gap-5">
          <h3 className="text-2xl md:text-3xl font-french-canon">
            Our Design Process:
          </h3>

          <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col gap-5">
                <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Initial Consultation and Space Planning:
                </h4>
                <p className="text-base md:text-lg">
                  Our design process begins with an in-depth consultation to
                  understand your needs and preferences.
                </p>
                <p className="text-base md:text-lg">
                  We then move on to space planning, ensuring every square foot
                  of your home is utilized effectively.
                </p>
              </div>
            </li>

            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col gap-5">
                <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Strategy and Concept Development:
                </h4>
                <p className="text-base md:text-lg">
                  We devise a tailored strategy to achieve your design goals.
                </p>
                <p className="text-base md:text-lg">
                  Our focus is on creating a seamless blend of aesthetics and
                  functionality, reflecting your unique style.
                </p>
              </div>
            </li>
          </ol>
        </div>
  <div className="w-full flex flex-col gap-5">
            <h3 className="text-2xl md:text-3xl font-french-canon">
              Our Services:
            </h3>
            <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
              <li className="flex   p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                    Space Planning:
                  </h4>
                  <p className="text-base md:text-lg">
                    Optimize the layout of your home for maximum efficiency and
                    comfort.
                  </p>
                </div>
              </li>

              <li className="flex   p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                    Furniture Selection:
                  </h4>
                  <p className="text-base md:text-lg">
                    Choose the perfect pieces to complement your space and
                    style.
                  </p>
                </div>
              </li>

              <li className="flex   p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                    Color and Material Selection:
                  </h4>
                  <p className="text-base md:text-lg">
                    Create a cohesive and harmonious color palette with the best
                    materials.
                  </p>
                </div>
              </li>

              <li className="flex p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-2xl  text-[#CCA605] font-french-canon">
                    Lighting Design:
                  </h4>
                  <p className="text-base md:text-lg">
                    Enhance the ambiance and functionality of your home with
                    well-planned lighting solutions.
                  </p>
                </div>
              </li>
            </ol>
          </div>
        <div className="flex flex-col xl:flex-row gap-5 md:gap-10 justify-between">
        
<div className="flex flex-col gap-5 w-full xl:w-2/3">
          <h3 className="text-2xl md:text-3xl font-french-canon">
            Our Commitment:
          </h3>
          <p className="text-base md:text-lg text-black  font-french-canon">
            Our experienced team of interior designers is dedicated to creating
            living spaces that are both beautiful and practical. Whether you
            prefer a modern or traditional look, we ensure the end result is a
            space you love and feel comfortable in. Our goal is to make the
            design process enjoyable and stress-free, providing the highest
            quality service possible.
          </p>
          <p className="text-base md:text-lg text-black  font-french-canon">
          Shape My House is not just an interior designing company in Noida but also a trusted partner in crafting homes that reflect your unique style and enhance your living experience.
          </p>
        </div>
          <img className="w-full xl:w-1/3" src={img1} alt="img1" />
        </div>

        

        <div className="flex flex-col gap-5">
          <h3 className="text-2xl md:text-3xl font-french-canon">
            Why Choose Shape My House?
          </h3>

          <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl ">
            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col gap-5">
                <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Comprehensive Design Solutions:
                </h4>
                <p className="text-base md:text-lg">
                  From luxury home designs to open terrace and wall designs, we
                  offer detailed drawings and plans to bring your vision to
                  life.
                </p>
              </div>
            </li>

            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col gap-5">
                <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Authenticity and Individuality:
                </h4>
                <p className="text-base md:text-lg">
                  We strive to create designs that reflect your individuality
                  and emotions, making your home truly yours.
                </p>
              </div>
            </li>

            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col gap-5">
                <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Core Values:
                </h4>
                <p className="text-base md:text-lg">
                  Our primary focus is to exceed your expectations by adhering
                  to our core values of quality, integrity, and customer
                  satisfaction.
                </p>
              </div>
            </li>

            <li className="flex   p-2 gap-1 md:gap-5 ">
              {/* <span className="text-black"> &#10146;</span> */}
              <div className="flex flex-col gap-5">
                <h4 className="text-lg md:text-2xl text-[#CCA605] font-french-canon">
                  Commercial and Residential Expertise:
                </h4>
                <p className="text-base md:text-lg">
                  We cater to both commercial and residential sectors, ensuring
                  that every project receives the same level of dedication and
                  excellence.
                </p>
              </div>
            </li>
          </ol>
        </div>
      </section>

      <section className="flex flex-col gap-10 md:gap-14 w-full p-5 md:p-20 md:py-0">
        {/* <div className="flex gap-5 mx-auto">
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_Specification}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile1)}
          >
            SMH Specificatio
          </PDFViewer>
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={SMH_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile2)}
          >
            SMH PPT
          </PDFViewer>
          <PDFViewer
            className="bg-green-900 font-semibold text-sm md:text-xl font-serif m-3  p-3 md:p-4 my-auto md:mr-8 md:px-8 mr-5 rounded-xl hover:bg-green-300 hover:text-black hover:font-bold focus:outline-none"
            // href={Gurgaon_PPT}
            // target="_blank"
            // rel="noopener noreferrer"
            file={() => openPdf(pdfFile3)}
          >
            Gurgaon PPT
          </PDFViewer>
        </div> */}

        <div className="flex flex-col md:flex-row gap-5 md:gap-10">
          <div className="bg-black flex flex-col justify-center items-center text-white font-glacial md:p-5 w-full xl:w-1/2 ">
            <img
              className="w-24 md:w-32 p-1 mx-auto m-3"
              src={logo}
              alt="logo"
            />
            <p className="text-base md:text-xl text-center p-3 font-french-canon">
              Consult with Our Expert & Start today
            </p>
            <div className="py-3">
              <a
                class="text-white text-base font-semibold hover:text-yellow-200"
                href="tel:8510850101"
              >
                <p className="text-lg md:text-2xl text-center font-sans tracking-widest">
                  8510 850 101
                </p>
              </a>
              <p className="text-lg text-center font-sans tracking-widest">
                Email:
                <a
                  className="text-white text-base md:hover:text-blue-400 "
                  href="mailto:info@tsgoc.in"
                >
                  info@tsgoc.in
                </a>
              </p>
            </div>
            <button
              onClick={handleButtonClick}
              className="w-fit bg-white text-[#eeb75e] border-2 border-[#eeb75e] hover:text-white hover:bg-[#F19F1A]  font-french-canon  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none"
            >
              Press
            </button>
          </div>

          <div className="flex flex-col gap-3  w-full xl:w-1/2">
            <a
              href="/service/construction"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Construction
            </a>
            <a
              href="/service/collaboration"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Collaboration
            </a>
            <a
              href="/service/renovation"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Renovation
            </a>
            <a
              href="/service/architectural-design"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Architectural Design
            </a>
            <a
              href="/service/vastu-consultancy"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Vastu Consultancy
            </a>
          </div>
        </div>

        {/* <img
          className="w-full"
          src="https://res.cloudinary.com/dpkvvpwv6/image/upload/v1719385551/ShapeMyHouse/Pics/InteriorPic_nnumly.webp"
          alt="img2"
        /> */}
      </section>
    </div>
  );
};

export default InteriorDesignDetails;
