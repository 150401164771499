"use client";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonials() {
  const isDesktop = typeof window !== "undefined" && window.innerWidth > 700;

  const testimonials = [
    {
      quote:
        "Did Collaboration with them in CR Park and they are literally the real modern innovators always ahead of the curve.",
      author: "Advocate Attri Gautam",
      location: "Delhi",
    },
    {
      quote:
        "Got in touch with them for renovation of my house in Brahmapuri, they excel in efficiency, quality work, and on-time delivery of projects.",
      author: "Yashpal Singh",
      location: "Gurugram",
    },
    {
      quote:
        " Highly Professional team they did my construction at DLF Phase 1 Gurugram.I was ensured about the operational quality at each stage of construction and the final quality at the time of project delivery was highly satisfactory.",
      author: "Harshit",
      location: "Noida",
    },
    {
      quote:
        "Shape My House team was truly a collaborative partner working with in a cost effective, efficient and quality construction product. The knowledge of their team was truly a value add to the overall success of our build-out project.",
      author: "Gaurav Gandhi",
      location: "Lead Website Developer (Noida)",
    },
    // Add more testimonials as needed
  ];

  var settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: isDesktop ? 3 : 1, // Adjust based on screen size
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="p-5 md:p-20 md:pt-10 flex flex-col gap-5 bg-white" data-scroll data-scroll-section data-scroll-speed="0.1">
        <div className="flex flex-col">
          <h2 className="text-[#CCA605] text-sm md:text-lg font-french-canon">
            Testimonials
          </h2>
          <h1 className="text-xl md:text-2xl xl:text-3xl  font-french-canon py-5">
            Our Customer Words
          </h1>
        </div>

        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <section
              key={index}
              className="flex flex-col md:pt-5 xl:pt-16 md:h-96 xl:h-80 justify-center gap-10 items-center text-center border-2 p-8 hover:bg-black hover:text-white bg-[#f1d9b1] border-white hover:border-[#F19F1A]"
            >
              <p className="text-pretty">{testimonial.quote}</p>
              <p className="font-semibold pt-5 text-lg md:text-xl font-times">
                {testimonial.author}
              </p>
              <p>{testimonial.location}</p>
            </section>
          ))}
        </Slider>
      </div>
    </>
  );
}


export default Testimonials;
