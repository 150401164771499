import React from "react";
// import PDFViewer from 'react-pdf-viewer';
import img1 from "../../assets/photos/vastu_page/VastuPic.webp";
// import img2 from "../../assets/photos/collaboration_page/img2.webp";
import logo from "../../assets/photos/ongoingProjects/customer-support.png";

// import SMH_Specification from "../../../../public/assets/pdf/SMH_Specification.pdf";
// import SMH_PPT from "../../../../public/assets/pdf/SMH_PPT.pdf"
// import Gurgaon_PPT from "../../../../public/assets/pdf/Gurgaon_PPT.pdf"

const VastuDetails = () => {
  const handleButtonClick = () => {
    window.open("/contact_us", "_blank");
  };

  return (
    <div className="flex flex-col">
      <section className="flex flex-col gap-5 md:gap-10 w-full p-5 md:p-20">
        <h2 className="text-2xl md:text-3xl font-french-canon">
          Shape My House: Vastu Consultancy Services in Delhi-NCR
        </h2>
        <p className="text-base md:text-lg text-black  font-french-canon">
          Welcome to Shape My House, where we blend ancient wisdom with modern
          design to create harmonious living spaces. Our Vastu Consultancy
          Services are dedicated to ensuring that your home or office not only
          looks beautiful but also promotes well-being, prosperity, and positive
          energy flow.
        </p>

        <h2 className="text-2xl md:text-3xl font-french-canon">
          Our Vastu Consultancy Services:
        </h2>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Personalized Vastu Consultation:
              </h4>
              <p className="text-xl">
                We start with an in-depth analysis of your existing space,
                understanding its current layout and energy flow.
              </p>
            </div>
          </li>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Client Needs:
              </h4>
              <p className="text-xl">
                We take into account your specific needs, concerns, and goals to
                tailor our Vastu recommendations.
              </p>
            </div>
          </li>
        </ol>

        <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <h3 className="text-2xl md:text-3xl font-french-canon">
            Comprehensive Vastu Analysis:
          </h3>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Site Inspection:
              </h4>
              <p className="text-xl">
                Our experts conduct a thorough inspection of the site,
                considering factors such as orientation, layout, and structural
                elements.
              </p>
            </div>
          </li>

          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Energy Mapping:
              </h4>
              <p className="text-xl">
                We create a detailed energy map of your space to identify areas
                that need improvement.
              </p>
            </div>
          </li>
        </ol>


        <div className="flex flex-col xl:flex-row gap-5 md:gap-10">

          <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
          <h3 className="text-2xl md:text-3xl font-french-canon">
            Customized Vastu Solutions:
          </h3>
          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Structural Modifications:
              </h4>
              <p className="text-xl">
                Suggesting structural changes to align with Vastu principles,
                ensuring better energy flow and balance.
              </p>
            </div>
          </li>

          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Interior Adjustments:
              </h4>
              <p className="text-xl">
                Recommending optimal placements for furniture, decor, and other
                interior elements to enhance positivity and harmony.
              </p>
            </div>
          </li>

          <li className="flex   p-2 gap-1 md:gap-5 ">
            {/* <span className="text-black"> &#10146;</span> */}
            <div className="flex flex-col gap-5">
              <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                Color and Material Selection:
              </h4>
              <p className="text-xl">
                Advising on the best colors and materials that align with Vastu
                principles to promote well-being.
              </p>
            </div>
          </li>
        </ol> 

        <img className="w-full xl:w-1/3" src={img1} alt="img1" />

        </div>
       


        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-5">
            <h2 className="text-2xl md:text-3xl font-french-canon">
              Integration with Architectural and Interior Design:
            </h2>
            <ol className="flex flex-col  gap-1 md:gap-5 w-full text-lg font-times md:text-2xl md:text-justify">
              <li className="flex   p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                    Seamless Coordination
                  </h4>
                  <p className="text-xl">
                    Our Vastu experts work closely with our architects and
                    interior designers to integrate Vastu principles into the
                    design process from the beginning.
                  </p>
                </div>
              </li>

              <li className="flex   p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                    Holistic Approach:
                  </h4>
                  <p className="text-xl">
                    Ensuring that every aspect of your home or office design
                    aligns with Vastu, creating a cohesive and harmonious
                    environment.
                  </p>
                </div>
              </li>
              <li className="flex   p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                    Experienced Vastu Experts:
                  </h4>
                  <p className="text-xl">
                    Our team comprises highly experienced and knowledgeable
                    Vastu consultants who are well-versed in traditional Vastu
                    Shastra.
                  </p>
                </div>
              </li>

              <li className="flex   p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                    Client-Centric Approach:
                  </h4>
                  <p className="text-xl">
                    We prioritize your well-being and satisfaction, offering
                    personalized solutions tailored to your specific needs.
                  </p>
                </div>
              </li>
              <li className="flex   p-2 gap-1 md:gap-5 ">
                {/* <span className="text-black"> &#10146;</span> */}
                <div className="flex flex-col gap-5">
                  <h4 className="text-lg md:text-xl text-[#CCA605] font-french-canon">
                    Integration with Modern Design:
                  </h4>
                  <p className="text-xl">
                    We seamlessly blend Vastu principles with modern
                    architectural and interior design, ensuring that your space
                    is both beautiful and harmonious.
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </div>



        <p className="text-base md:text-lg text-black w-full font-french-canon">
          Shape My House is your trusted partner for all your needs of Vastu consultancy in Noida. Let us help you create a space that is not only
          aesthetically pleasing but also filled with positive energy and
          harmony. Reach out to us today!
        </p>
      
    

      </section>

      <section className="flex flex-col gap-10 md:gap-14 w-full p-5 md:p-20 md:pt-0">
        <div className="flex flex-col md:flex-row gap-5 md:gap-10">
          <div className="bg-black  w-full xl:w-1/2  flex flex-col justify-center items-center text-white font-glacial md:p-5">
            <img
              className="w-24 md:w-32 p-1 mx-auto m-3"
              src={logo}
              alt="logo"
            />
            <p className="text-base md:text-xl text-center p-3 font-french-canon">
              Consult with Our Expert & Start today
            </p>
            <div className="py-3">
              <a
                class="text-white text-base font-semibold hover:text-yellow-200"
                href="tel:8510850101"
              >
                <p className="text-lg md:text-2xl text-center font-sans tracking-widest">
                  {" "}
                  8510 850 101
                </p>
              </a>
              <p className="text-lg text-center font-sans tracking-widest">
                Email:{" "}
                <a
                  className="text-white text-base md:hover:text-blue-400 "
                  href="mailto:info@tsgoc.in"
                >
                  info@tsgoc.in
                </a>
              </p>
            </div>
            <button
              onClick={handleButtonClick}
              className="w-fit bg-white text-[#eeb75e] border-2 border-[#eeb75e] hover:text-white hover:bg-[#F19F1A]  font-french-canon  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none"
            >
              Press
            </button>
          </div>
          <div className="flex flex-col w-full xl:w-1/2 gap-3">
            <a
              href="/service/construction"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Construction
            </a>
            <a
              href="/service/collaboration"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Collaboration
            </a>
            <a
              href="/service/renovation"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Renovation
            </a>

            <a
              href="/service/interior-design"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Interior Design
            </a>
            <a
              href="/service/architectural-design"
              target="_blank"
              className="text-lg md:text-2xl px-3 text-pretty hover:border-l-4 font-french-canon hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2"
            >
              Architectural Design
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VastuDetails;
