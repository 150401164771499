import React from "react";
import img from "../../assets/photos/blogs/SMH0010.jpg";
import { Helmet } from "react-helmet";

const Blog10 = () => {
  return (
    <div className="flex flex-col md:flex-row bg-zinc-100">
      <Helmet>
        <title>Vital Trends In Construction Industry To Watch For In 2023</title>
        <meta
          name="description"
          content="Year 2023 is looking to be wonderful for construction business and construction companies. With the vast demand for services only upward movement is possible."
        />
      </Helmet>
      <section className="w-full md:w-2/3 p-3 md:p-10">
        <div className="flex flex-col gap-5 py-5 md:py-10">
          <span className="text-xl font-sans  font-thin">
          2023-01-13 23:26:44
          </span>
          <h1 className="text-xl md:text-4xl font-french-canon text-[#F19F1A] bg-cover">
          Key Construction Industry Trends To Look Out For In <span className="font-sans font-semibold">2023</span>
          </h1>
        </div>

        <img className="w-full" src={img} alt="Key Construction Industry Trends" />
        <div className="w-full my-10 flex flex-col gap-3">
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          2023 is shaping up to be a fantastic year for construction companies, construction industries and construction business . With technological advancements and high demand for services, the only way to go is up.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The global construction industry’s future looks promising, with opportunities in residential, non-residential, and infrastructure sector. The global construction industry is expected to reach $10.5 trillion by 2023, growing at a CAGR of 4.2% between 2018 and 2023. The major drivers of this market’s growth are rising housing starts and rising infrastructure and a construction business as a result of increasing urbanization and population growth.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          India is on the move towards a phase of sustained growth in the infrastructure build-up. The construction industry regained growth movement in 2018 as well as 2019, with output hovering around 8% which was at 1.9% in 2017. The industry is expected to continue to expand over the period (2022-2023), driven by the government’s efforts and large spending on housing, road, ports, water supply, and airport development. The government had increased its expenditure towards infrastructure development by 20% to INR 6.0 trillion (US$89.2 billion) in FY 2018-2019 with continued investment in transport infrastructure, energy, and residential projects under flagship programs such as Bharatmala scheme, Housing for All 2022, the UDAN (Ude Desh ka Aam Nagrik) scheme and the Ayushman Bharat program. Population growth and urbanization will also drive the need for better infrastructure facilities and road infrastructure developments in the country.
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Emerging trends that have a direct impact on the dynamics of the construction industry include a rise in the need for green construction to reduce carbon footprint, bridge lock-up device systems to improve structure life, building information systems for efficient building management, and the use of cutting-edge technologies. Let us look at some of them in detail.
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Robotics
          </p>
          
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Robots are making a difference in a variety of industries, from agricultural production to medicine. These high-tech machines have also transformed the construction industries.. Robots could assist construction projects to stay on track and under budget. However, interested businesses must investigate which machines are most appropriate for assisting with time-consuming or labor-intensive tasks.
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Green Building
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The construction industry contributes significantly to fossil fuel emissions, which contribute to climate change. Building operations and construction account for nearly 40% of global energy-related CO2 emissions. However, as global awareness of the severity of climate change grows, the construction industry is taking concrete steps to reduce its environmental impact. Industry leaders are looking for ways to reduce material waste from demolitions, switch to eco-friendly building materials, and choose locally sourced building products. Construction clients are also investing in companies that provide the sustainable, environmentally-friendly practices they seek.
          </p>

          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Remote Worksites
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Construction firms navigated strict safety protocols while maintaining productivity throughout 2020. Mobile construction apps have enabled the industry to expand while keeping workers, clients, and contractors safe. As we approach 2023, construction companies recognise the value of remote work sites and mobile access to their bottom line. Many phases of construction can be completed remotely, saving both the builder and the client time and money. More technological advancements that enable work to be done offsite are expected, allowing projects to be finished faster than ever before.
          </p>
          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Modular Design
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Modular construction is rapidly becoming a preferred approach of constructing in the construction field. A modular building is built offsite and then assembled on-site. This process allows for a faster construction schedule, a greener building process, and smarter design. The modular building process also produces less waste. Building crews can recycle used materials and keep track of inventory. Furthermore, the buildings can be refurbished or relocated, reducing the amount of energy and materials required for a new project. According to projections, the modular construction market will be worth up to $157 billion by 2023.
          </p>
          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Use of Rented equipment
          </p>

          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Construction companies will continue to consider renting equipment or purchasing used merchandise rather than purchasing new items. Both of these options are construction industry trends for 2023 because they allow businesses to keep up with technological advancements at lower costs. Rented equipment, in particular, allows construction companies to test whether different machines deliver the expected results.
          </p>
        </div>
      </section>

      <section className="w-full flex flex-col gap-3 md:gap-5 md:w-1/3 py-10 md:py-20 font-french-canon">
        <a href="/blog/know-why-home-orientation-is-necessary" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Know Why Home Orientation Is Necessary
        </a>

        <a href="/blog/the-development-and-future-of-the-construction-industry-in-india" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        The Development and future of the Construction Industry in India
        </a>

        <a href="/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
        </a>

        <a href="/blog/where-to-purchase-residential-plots-in-gurgaon?" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Where to purchase residential plots in Gurgaon?
        </a>

        <a href="/blog/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Construction ban reimposed in Delhi NCR as air quality worsens
        </a>

        <a href="/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Factors that make house in Gurugram the hot property at the moment -
        </a>

        <a href="/blog/simple-options-for-home-renovation-in-india--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Simple Options for Home Renovation in India -
        </a>

        <a href="/blog/upcoming-smart-home-trends-&-innovations-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Upcoming Smart Home Trends & Innovations in <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/performance-of-indian-real-estate-through-fy23-and-future-prospects" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Performance Of Indian Real Estate Through FY<span className="font-sans font-semibold">23</span> And Future Prospects
        </a>

        <a href="/blog/Modern-Homes-vs-Ancient-Homes:-Top-details-you-were-Overlooked" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Modern Homes vs Ancient Homes: Top <span className="font-sans font-semibold">6</span> details you've Overlooked
        </a>

        <a href="/blog/top-5-collaboration-tools-for-real-estate" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Top <span className="font-sans font-semibold">5</span> Collaboration Tools for Real Estate
        </a>

        <a href="/blog/introduction-of-the-best-builders-of-delhi---shape-my-house" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Introduction of the Best Builder of Delhi - Shape My House
        </a>

        <a href="/blog/how-do-you-figure-out-the-property-valuation" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        How do you figure out the Property Valuation
        </a>
      </section>
    </div>
  );
};

export default Blog10;
