import React from 'react'
import ConstructionBanner from '../component/servicepage/ConstructionBanner'
import ConstructionDetails from '../component/servicepage/ConstructionDetails'
import Header2 from './Header2'
import Footer from './Footer'
import { Helmet } from 'react-helmet';


const Construction = () => {
  return (
    <div>
          <Helmet>
        <title>Construction Services in Noida- Top Colonizers in Noida</title>
        <meta name="description" content="Looking for the best construction company in Noida? Shape My House, your premier builder and colonizer in Noida brings expertise to every construction project." />
      </Helmet>
      <Header2/>
      <ConstructionBanner/>
      <ConstructionDetails/>
      <Footer/>
    </div>
  )
}

export default Construction
