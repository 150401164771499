import React from 'react';
import { Link } from 'react-router-dom';

import banner from "../../assets/photos/vendor_page/banner.webp";

const VendorBanner = () => {
  return (
    <div
      className="h-[50vw] bg-cover bg-center flex justify-center items-center w-screen"
      style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    ><h3 className='text-white text-3xl md:text-6xl z-10 font-french-canon text-center'>Vendor Registration
     {/* <Link to="/">
          <span className=" text-black text-lg md:text-2xl  bg-[#f6c87f] hover:text-[#f6c87f]  md:px-2 p-1 hover:bg-white">HOME</span>
          </Link>  */}
    </h3>
   
      
    </div>
  );
};

export default VendorBanner;
