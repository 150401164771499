import React from 'react'
import VendorBanner from '../component/vendor/VendorBanner'
import VendorContent from '../component/vendor/VendorContent'
import Header2 from './Header2'
import Footer from './Footer'
const Vendor = () => {
  return (
    <div className='bg-[#f0d6aa]'>
      <Header2/>
      <VendorBanner/>
      <VendorContent/>
      <Footer/>
    </div>
  )
}

export default Vendor
