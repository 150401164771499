import React from 'react'
import ContactBanner from '../component/contact/ContactBanner'
import ContactDetails from '../component/contact/ContactDetails'
import Header2 from "./Header2"
import Footer from './Footer'
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  return (
    <div className='bg-[#f0d6aa]'>
        <Helmet>
        <title>Contact | construction company in noida</title>
        <meta name="description" content="Are You Looking for the best construction company in Delhi, Noida, Gurgaon, Ghaziabad, Greater Noida or Faridabad then Welcome To Shape My House - A residential and commercial property reliable civil construction company in Delhi NCR" />
      </Helmet>
      <Header2/>
      <ContactBanner/>
      <ContactDetails/>
      <Footer/>
    </div>
  )
}

export default ContactUs
