import React from 'react'
import CollaborationBanner from '../component/servicepage/CollaborationBanner'
import CollaborationDetails from '../component/servicepage/CollaborationDetails'
import Header2 from './Header2'
import Footer from './Footer'
import { Helmet } from 'react-helmet';

const Collaboration = () => {
  return (
    <div>
        <Helmet>
        <title>Property Collaboration in Delhi, Gurgaon, Noida and Faridabad</title>
        <meta name="description" content="Property Collaboration company in Delhi, Gurgaon, Noida, Ghaziabad, Faridabad, Greater Noida and NCR location by highly experienced professionals." />
      </Helmet>
      <Header2/>
      <CollaborationBanner/>
      <CollaborationDetails/>
      <Footer/>
    </div>
  )
}

export default Collaboration
