import React from "react";
import img from "../../assets/photos/blogs/SMH009.jpg";
import { Helmet } from "react-helmet";

const Blog9 = () => {
  return (
    <div className="flex flex-col md:flex-row bg-zinc-100">
      <Helmet>
        <title>Execution Of Real Estate India Through FY23 And Future hope</title>
        <meta
          name="description"
          content="If talk about real estate news then housing market has touched the heights in 2022 and there are some much better prospects of the real estate business in 2023"
        />
      </Helmet>
      <section className="w-full md:w-2/3 p-3 md:p-10">
        <div className="flex flex-col gap-5 py-5 md:py-10">
          <span className="text-xl font-sans  font-thin">
          2023-01-07 02:29:53


          </span>
          <h1 className="text-xl md:text-4xl font-french-canon text-[#F19F1A] bg-cover">
          Performance Of Indian Real Estate Through FY<span className="font-sans font-semibold">23</span> And Future Prospects
          </h1>
        </div>

        <img className="w-full" src={img} alt="Indian Real Estate in FY 23" />
        <div className="w-full my-10 flex flex-col gap-3">
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          The real estate business has experienced a significant recovery despite growing building costs and a record increase in the repo rate (225 bps) in 2022. The real estate sector was finally able to take a deep breath this fiscal year after a protracted period of economic stagnation. After two protracted years of lockdowns brought on by the pandemic and the consequent economic instability, the industry has seen a thorough recovery this year across Tier I, II, and III cities. 
          </p>


          <p className="text-lg md:text-2xl p-3 font-french-canon text-center rounded-2xl">
          Some thoughts mentioned below are given by prominent leaders and experts in Real Estate -
          </p>
          
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          One of the experts in the real estate industry commented on the real estate market's performance through FY23 and its prospects - "The theme of the year 2022 has been of appreciation. According to the Indian real estate news, industry has expanded despite recent ups and downs, giving investors and homebuyers equal confidence. Since the pandemic began, there has been a sustained increase in the demand for homeownership. Homebuyers are searching for larger, greener places with value-added services and facilities that improve their well-being as a result of trends brought on by the pandemic, such as remote and hybrid working. Customers are also drawn to outlying locations that are close to urban centers as a result of this evolution in purchasing preferences. Additionally, the pandemic made real estate India appear as a more reliable option for investment, and this perception was further reinforced by the sector's year-over-year performance”. 
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          "Home loan interest rates increased as a result of RBI raising repo rates. In the past, sales and consumer satisfaction were little impacted by this, though. Additionally, companies' accommodating payment arrangements have pushed purchasers to finish their purchases. Furthermore, this was the year that the new generation of environmentally concerned homebuyers began to push for creative and sustainable products. Even with the likelihood of additional rate increases, the market is likely to react favorably, as it has in the past 12 months. We expect this momentum to last over the upcoming year and are still optimistic about the industry's overall growth”, according to him.  
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          Another leading expert of Real Estate says, "Figures at the end of 2022, and surprisingly, the more expensive luxury and high-end sectors are showing a marked upward swing in off-take throughout the country, despite the pandemic's tail-end, interest rate hike, job losses, a sluggish economy, and everything else taking place in the world to slow things down. A slow and cautious economy, interest rate increases, and overall increases in real estate expenses due to increased raw material prices could all make it difficult to find affordable options in the housing market. Not that it has no impact on the higher, more expensive, and more opulent reality. It's just that with higher buying power, the latter is better placed to tackle rising prices, whereas those at the bottom of the barrel think twice before loading their already creaking (and leaking) monthly budget." 
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          "The area concerned with infrastructure, logistics, and to some extent, manufacturing is the only one where things are clearly improving. The Central Government has made a significant effort to build infrastructure, including a dedicated railway freight corridor, world-class multiple-laned express highways that interconnect the nation (a far cry from the conventional 4-laned highways of yore), airports, railways, and even bus terminals that could rival (or even surpass!) the best in the west. Manufacturing and allied service organizations, including 3PL and 4PL entities, have implemented vast production, storage, and processing capacities that India had previously only heard about and seen in movies as a result of these advances".
          </p>
          <p className="text-md md:text-xl px-3 font-glacial  text-justify rounded-2xl">
          "Since Covid changed manufacturing to be China+1, India is benefiting greatly, with companies like Apple and Samsung (and with others like Oppo, Vivo, and the like) establishing sizable production facilities there. So, this is only the start. As China undergoes a second cycle of Covid, more businesses are anticipated to move their headquarters to India. In addition to their workforce needing the necessities of life, which would increase the need for reality, the thing they would need the most to start their business is a plot of land. It only means one thing towards the tail end of 2022. 2023 will bring forth some seriously good times”.
          </p>
         
        </div>
      </section>

      <section className="w-full flex flex-col gap-3 md:gap-5 md:w-1/3 py-10 md:py-20 font-french-canon">
        <a href="/blog/know-why-home-orientation-is-necessary" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Know Why Home Orientation Is Necessary
        </a>

        <a href="/blog/the-development-and-future-of-the-construction-industry-in-india" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        The Development and future of the Construction Industry in India
        </a>

        <a href="/blog/residential-projects-in-gurgaon---sohna-road-vs-new-gurgaon" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Residential Projects in Gurgaon - Sohna Road vs New Gurgaon
        </a>

        <a href="/blog/where-to-purchase-residential-plots-in-gurgaon?" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Where to purchase residential plots in Gurgaon?
        </a>

        <a href="/blog/construction-ban-reimposed-in-delhi-ncr-as-air-quality-worsens" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Construction ban reimposed in Delhi NCR as air quality worsens
        </a>

        <a href="/blog/factors-that-make-house-in-gurugram-the-hot-property-at-the-moment--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Factors that make house in Gurugram the hot property at the moment -
        </a>

        <a href="/blog/simple-options-for-home-renovation-in-india--" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Simple Options for Home Renovation in India -
        </a>

        <a href="/blog/upcoming-smart-home-trends-&-innovations-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Upcoming Smart Home Trends & Innovations in <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/key-construction-industry-trends-to-look-out-for-in-2023" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Key Construction Industry Trends To Look Out For In <span className="font-sans font-semibold">2023</span>
        </a>

        <a href="/blog/Modern-Homes-vs-Ancient-Homes:-Top-details-you-were-Overlooked" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Modern Homes vs Ancient Homes: Top <span className="font-sans font-semibold">6</span> details you've Overlooked
        </a>

        <a href="/blog/top-5-collaboration-tools-for-real-estate" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Top <span className="font-sans font-semibold">5</span> Collaboration Tools for Real Estate
        </a>

        <a href="/blog/introduction-of-the-best-builders-of-delhi---shape-my-house" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        Introduction of the Best Builder of Delhi - Shape My House
        </a>

        <a href="/blog/how-do-you-figure-out-the-property-valuation" target="_blank" className="text-md md:text-lg px-3 text-pretty hover:border-l-4 hover:border-black bg-white hover:bg-[#eecf9d]  border-l-2 border-[#F19F1A] p-2">
        How do you figure out the Property Valuation
        </a>
      </section>
    </div>
  );
};

export default Blog9;
