import React from "react";
import brand_logo from "../assets/logos/the-shubham-group2.png";
import { IoLogoInstagram } from "react-icons/io";
import { RiFacebookBoxLine } from "react-icons/ri";
import { AiOutlineLinkedin } from "react-icons/ai";
import { LiaPinterestSquare } from "react-icons/lia";

const Footer = () => {
  return (
    <footer className=" bg-[#b6ad99] flex flex-col justify-center items-center w-full  bottom-0 p-5 xl:px-20">
      <div className=" mx-auto flex flex-col md:flex-row  justify-between m-5  gap-5 xl:gap-10">
      <section className="w-full md:w-1/4">
          <a href="https://www.tsgoc.in" target="_blank" rel="noreferrer">
            <img
              className=" w-40 md:w-80 xl:w-60 mx-auto rounded-lg hover:shadow-lg hover:shadow-slate-300 animate__zoomIn"
              src={brand_logo}
              alt="Brand Logo.."
            />
          </a>
        </section>
        <div className="flex flex-col w-full md:w-1/4 ">
          <p className="text-[#EABB26] text-2xl md:text-4xl font-glacial">
            Address
          </p>
          <section className="flex flex-col w-full md:pt-5 gap-3">
            <p className="text-white text-lg md:text-2xl font-glacial">
              Registered Office
            </p>
            <p className="text-white text-base font-times font-thin ">
              The Shubham Group, A-24, Okhla Industrial Area, Phase-2, Delhi.
              Pincode - 110020
            </p>
          </section>

          <section className="flex flex-col w-full md:pt-5 gap-3">
            <p className="text-white text-lg md:text-2xl font-glacial">
              Corporate Office
            </p>
            <p className="text-white text-base font-times font-thin  ">
              315, Block-B, Sector-105, Noida, Uttar Pradesh Pincode - 201304
            </p>
          </section>
        </div>

        <div className="flex flex-col w-full md:w-1/4 ">
          <section className="flex flex-col w-full md:px-10 gap-5">
            <p className="text-[#EABB26] text-2xl md:text-3xl font-glacial">
              Contact Us
            </p>
            <div className="flex flex-col">
              <a
                className="text-white text-base font-semibold  md:hover:text-blue-400 "
                href="mailto:info@tsgoc.in"
              >
                info@tsgoc.in
              </a>

              <a
                class="text-white text-base font-semibold  hover:text-yellow-200"
                href="tel:8510850101"
              >
               9870 168 989
              </a>
            </div>
          </section>
          <section className="flex w-full md:px-8 md:pt-10 pt-5 gap-5">
          <a
                class="text-white text-base font-semibold  hover:text-yellow-200"
                href="https://www.facebook.com/ShapeMyHouse/"
              >
          <RiFacebookBoxLine className="text-white size-10 md:hover:scale-110 md:hover:shadow-white md:hover:shadow-xl duration-500"/>
          </a>
          <a
                class="text-white text-base font-semibold  hover:text-yellow-200"
                href="https://www.instagram.com/shapemyhouse/"
              >
          < IoLogoInstagram className="text-white size-10 md:hover:scale-110 md:hover:shadow-white md:hover:shadow-xl duration-500"/>
          </a>
          <a
                class="text-white text-base font-semibold  hover:text-yellow-200"
                href="https://www.linkedin.com/company/shape-my-house/"
              >
          < AiOutlineLinkedin className="text-white size-10 md:hover:scale-110 md:hover:shadow-white md:hover:shadow-xl duration-500"/>
          </a>
          <a
                class="text-white text-base font-semibold  hover:text-yellow-200"
                href="https://in.pinterest.com/shapemyhouse_/"
              >
          <LiaPinterestSquare className="text-white size-10 md:hover:scale-110 md:hover:shadow-white md:hover:shadow-xl  duration-500"/>
          </a>
          </section>
        </div>
        <div className="flex flex-col w-full md:w-1/4 ">
          <p className="text-[#EABB26] text-2xl md:text-3xl  md:px-10 font-glacial">
            Quick Links
          </p>
          <section className="flex flex-col w-full md:px-10 md:pt-5 gap-3">
            <a
              className="text-white text-base font-glacial  md:hover:text-blue-400 "
              href="/about_us"
            >
              About
            </a>
            <a
              className="text-white text-base font-glacial  md:hover:text-blue-400 "
              href="/contact_us"
            >
              Contact Us
            </a>
            <a
              className="text-white text-base font-glacial  md:hover:text-blue-400 "
              href="/our-projects"
            >
             Our Projects
            </a>
            <a
              className="text-white text-base font-glacial md:hover:text-blue-400 "
              href="/vendor_registration"
            >
              Vendor Registration
            </a>
            <a
              className="text-white text-base font-glacial md:hover:text-blue-400 "
              href="/blog"
            >
             Blogs
            </a>
            <a
              className="text-white text-base font-glacial md:hover:text-blue-400 "
              href="/privacy_policy"
            >
              Privacy Policy
            </a>
          </section>
        </div>
       
      </div>
      <section class="copy-right">
        <p className="text-white text-center text-sm font-times ">
          Copyright © 2009 The Shubham Group of Companies all rights reserved.
        </p>
      </section>
    </footer>
  );
};

export default Footer;
