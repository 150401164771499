import React from "react";

function NoticeBanner() {
  const handleButtonClick = () => {
    window.open("/vendor_registration", "_blank");
  };

  return (
    <div
      className="flex flex-col md:flex-row justify-center items-center md:gap-10 bg-[#f0d6aa] h-[150px] md:h-[130px]"
      // data-scroll
      // data-scroll-section
      // data-scroll-speed="0.2"
    >
      <h2 className="flex flex-col xl:flex-row items-center text-lg md:text-2xl xl:text-3xl font-french-canon text-center xl:gap-2 text-white p-3">
        Want to work with our professional team?
        <span className="text-base md:text-xl font-thin pt-1 text-black font-serif">  Make an Appointment.
        </span>
      </h2>

      <button
        onClick={handleButtonClick}
        className="w-fit bg-black text-[#f6c87f]  hover:text-white hover:bg-[#937B4D]  font-glacial  md:text-xl m-3 py-1  md:p-2 md:px-14  px-8  rounded-2xl  hover:border-[#F5F3EF] focus:outline-none">
        Press
      </button>
    </div>
  );
}

export default NoticeBanner;
