import React from "react";

const VendorContent = () => {
  return (
    <div>
      <div className="w-full md:w-3/4 mx-auto p-2 md:px-12">
        <h3 className="p-3 text-4xl text-start md:py-10 md:text-4xl font-french-canon">
          HOW CAN WE HELP YOU?
        </h3>
      </div>

      <div className="flex flex-col gap-10 p-5 md:p-20 md:pt-0 justify-center items-center">
        <section className="flex flex-col gap-2 md:gap-5 w-full md:w-3/4 md:p-10  bg-black hover:bg-slate-900 ">
          <h3 className="text-3xl text-[#F19F1A] md:text-4xl font-french-canon pt-5 p-3 font-semibold">
            Make Appointment
          </h3>
          <p className="text-lg md:text-2xl text-white font-serif p-3 text-pretty">
            Seeking for honest & transparent registration providers (Interior
            Designers/ Builders/Contractors/Architects agents) for our esteemed
            clients. If your work style matches our parameters of exellence, do
            contact us.
          </p>

          <iframe
            className="text-3xl md:text-4xl size-[500px] w-full text-white rounded-2xl md:py-3 font-semibold font-serif"
            title="CRM-Form"
            id="Vendor_Form"
            src="https://account.solidperformers.com/capture_form_data/MTAxMQ=="
            border="0"
            style={{ border: 0 }}
          ></iframe>
        </section>
      </div>
    </div>
  );
};

export default VendorContent;
