import React from 'react'
import InteriorDesignDetails from '../component/servicepage/InteriorDesignDetails'
import InteriorDesignBanner from '../component/servicepage/InteriorDesignBanner'
import Header2 from './Header2'
import Footer from './Footer'
import { Helmet } from 'react-helmet';

const InteriorDesign = () => {
  return (
    <div>
        <Helmet>
        <title> Interior Designing Company In Noida | Shape My House</title>
        <meta name="description" content="Get personalized interior design service in Noida, Delhi, and Gurgaon with our interior designing firm. Let our designers create spaces that inspire and delight" />
      </Helmet>
        <Header2/>
        <InteriorDesignBanner/>
      <InteriorDesignDetails/>
      <Footer/>
    </div>
  )
}

export default InteriorDesign
