import React from 'react'
import { Helmet } from 'react-helmet';
import { GoDotFill } from "react-icons/go";

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col gap-5">
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Welcome to ShapeMyHouse.com (https://www.shapemyhouse.com). At ShapeMyHouse, we are committed to protecting your privacy and ensuring that your personal information is handled in a secure and responsible manner." />
      </Helmet>
   

      <div className="flex flex-col gap-5 mb-10 justify-center">
        <h1 className="text-3xl md:text-5xl font-french-canon md:py-10 text-black text-center bg-cover">
        PRIVACY POLICY
        </h1>

        <p className="text-sm md:text-xl px-3 md:px-20 font-glacial text-justify">
        Welcome to ShapeMyHouse.com (https://www.shapemyhouse.com). At ShapeMyHouse, we are committed to protecting your privacy and ensuring that your personal information is handled in a secure and responsible manner. This Privacy Policy outlines how we collect, use, and safeguard your personal data when you visit our Website or interact with our services.
        </p>
        <p className="text-lg md:text-2xl text-black px-3 md:px-20 font-french-canon text-justify">
        Information We Collect
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20 font-glacial text-justify">
        When you visit ShapeMyHouse.com, we may collect certain information about you, including:
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20 font-glacial text-justify">
        <span className='italic'>Personal Information:</span>  Such as your name, email address, and contact details, which you voluntarily provide when filling out forms on our Website or contacting us directly.
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20 font-glacial text-justify">
        <span className='italic'> Usage Information:</span>  Including your IP address, browser type, operating system, and other technical data collected through cookies and similar tracking technologies.
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20 font-glacial text-justify">
        <span className='italic'> Device Information:</span> Such as device identifiers and location data, if you access our Website via a mobile device.
        </p>


        <p className="text-lg md:text-2xl text-black px-3 md:px-20 font-french-canon text-justify">
        How We Use Your Information
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20  font-glacial text-justify">
        We may use the information we collect for various purposes, including:
        </p>
        <p className="text-sm flex md:text-xl px-3 md:px-20  font-glacial text-justify">
       <GoDotFill className='m-1'/> Providing and improving our services, products, and Website functionality.
        </p>

        <p className="text-sm flex md:text-xl px-3 md:px-20  font-glacial text-justify">
       <GoDotFill className='m-1'/> Communicating with you about your inquiries, orders, and account-related activities.
        </p>
        <p className="text-sm flex md:text-xl px-3 md:px-20  font-glacial text-justify">
       <GoDotFill className='m-1'/> Personalizing your experience on our Website and delivering relevant content and advertisements.
        </p>
        <p className="text-sm flex md:text-xl px-3 md:px-20  font-glacial text-justify">
       <GoDotFill className='m-1'/> Analyzing trends and user behavior to enhance our marketing efforts and user experience.
        </p>
        <p className="text-sm flex md:text-xl px-3 md:px-20  font-glacial text-justify">
       <GoDotFill className='m-1'/> Complying with legal obligations and protecting our rights and interests.
        </p>
        <p className="text-lg md:text-2xl text-black px-3 md:px-20 font-french-canon text-justify">
        Information Sharing and Disclosure
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20  font-glacial text-justify">
        ShapeMyHouse does not sell, rent, or trade your personal information to third parties for marketing purposes. However, we may share your data with trusted third-party service providers who assist us in operating our Website, conducting business, or servicing you, as long as those parties agree to keep this information confidential. 
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20  font-glacial text-justify">
        ShapeMyHouse does not sell, rent, or trade your personal information to third parties for marketing purposes. However, we may share your data with trusted We may also disclose your information when required by law, to enforce our Website policies, or to protect ours or others' rights, property, or safety.
        </p>


        <p className="text-lg md:text-2xl text-black px-3 md:px-20 font-french-canon text-justify">
        Data Security
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20  font-glacial text-justify">
        We implement appropriate security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security. 
        </p>

        <p className="text-lg md:text-2xl text-black px-3 md:px-20 font-french-canon text-justify">
        Your Rights and Choices
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20  font-glacial text-justify">
        You have the right to access, update, or delete your personal information, as well as to opt-out of certain communications from us. You can exercise these rights by contacting us using the information provided below. 
        </p>

        <p className="text-lg md:text-2xl text-black px-3 md:px-20 font-french-canon text-justify">
        Changes to This Privacy Policy
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20  font-glacial text-justify">
        ShapeMyHouse reserves the right to update or modify this Privacy Policy at any time. We encourage you to review this Privacy Policy periodically for any changes. 
        </p>

        
        <p className="text-lg md:text-2xl text-black px-3 md:px-20 font-french-canon text-justify">
        Contact Us
        </p>
        <div>
              <p className="text-sm md:text-xl px-3 md:px-20  font-glacial text-justify">
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at <span className='italic'>info@tsgoc.in</span> 
        </p>
        <p className="text-sm md:text-xl px-3 md:px-20  font-glacial text-justify">
        Thank you for visiting ShapeMyHouse.com and trusting us with your personal information.
        </p>
        </div>
      
      </div>
  
  </div>
  )
}

export default PrivacyPolicy
